<template>
    <div>
        <OptionsModal
            v-if="process.id"
            id="processOptions"
            :allow-multiple-drafts="false"
            data-analytics-location="Behandle Options Modal"
            :item="process"
            class="options-modal"
            :permissions="{
                update: canManage,
                update_status: canManage,
                delete: canManage,
            }"
            :saving="[]"
            static
            visible
            @hidden="handleHidden"
            @item:obsolete="handleObsolete"
            @item:delete="handleDeleteProcess"
            @item:unschedule-obsolete="handleUnscheduleObsolete"
            @version:create-draft="handleCreateDraft"
            @version:publish="handlePublish"
            @version:delete="handleDeleteVersion"
            @version:unschedule-publish="handleUnschedulePublish"
            @version:revert="handleRevert"
            @version:unschedule-revert="handleUnscheduleRevert"
        >
            <template #additional-info>
                <div class="form-group">
                    <label>Code</label>
                    <div class="title font-weight-normal">
                        {{ process.code }}
                    </div>
                </div>
            </template>

            <template #version-content="{ version }">
                <div class="version-details col-12 pt-2">
                    <div class="col-12 p-0">
                        <div class="form-group mb-2">
                            <label class="mb-0">Title</label>
                            <div
                                class="font-weight-normal"
                                test-data="version-title"
                            >
                                {{ version.title }}
                            </div>
                        </div>
                    </div>

                    <div class="col-12 p-0">
                        <div class="form-group mb-2">
                            <label class="mb-0">Process Type</label>
                            <div
                                class="font-weight-normal"
                                test-data="process-type"
                            >
                                {{ version.processType }}
                            </div>
                        </div>
                    </div>

                    <div class="col-12 p-0">
                        <div class="form-group mb-1">
                            <label class="mb-0">Tasks</label>
                            <div
                                v-for="task in version.tasks"
                                :key="task.id"
                                :test-data="`task-${task.id}`"
                            >
                                <p class="font-weight-normal">
                                    {{ task.function }}
                                </p>
                            </div>

                            <div v-if="!version.tasks.length">
                                <span class="text-muted">None</span>
                            </div>
                        </div>
                    </div>

                    <RelatedItemView
                        :item-id="process.id"
                        item-type="process"
                        :item-version-id="version.id"
                        class="mt-2"
                    />
                </div>
            </template>

            <template #options-left="{ version }">
                <RouterLink
                    v-if="version.publishedAt === null && canManage"
                    :to="{ name: 'admin.process-builder', params: { id: version.id }}"
                    class="btn btn-tertiary mr-1"
                    data-cy="editVersion"
                >
                    <FontAwesomeIcon
                        icon="pencil-alt"
                        class="mr-1"
                    />

                    Edit Version
                </RouterLink>
                <RouterLink
                    v-else
                    :to="{ name: 'admin.process-builder', params: { id: version.id }}"
                    class="btn btn-tertiary mr-1"
                    data-cy="viewVersion"
                >
                    <FontAwesomeIcon
                        icon="eye"
                        class="mr-1"
                    />

                    View Process
                </RouterLink>
            </template>
        </OptionsModal>
    </div>
</template>

<script>
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import OptionsModal from '@imt/vue-versioning/src/components/OptionsModal.vue';
    import RelatedItemView from '@imt/vue-versioning/src/components/RelatedItemView.vue';
    import VersionMixin from '@imt/vue-versioning/src/mixins/versioning';
    import {mapActions, mapMutations} from 'vuex';

    import authMixin from '@/mixins/auth';

    export default {
        name: 'BHProcessOptions',
        components: {
            OptionsModal,
            RelatedItemView,
        },
        mixins: [
            authMixin,
            toastsMixin,
            VersionMixin,
        ],
        data() {
            return {
                process: {},
            };
        },
        async created() {
            this.SET_DATA({field: 'saving', data: true});
            await this.initialize();
            this.SET_DATA({field: 'saving', data: false});
        },
        methods: {
            async initialize() {
                try {
                    this.process = await this.fetchProcess(this.$route.params.id);
                } catch {
                    await this.$router.push({name: 'admin.processes.list'});
                    this.error('This process was not found or does not exist.', 'Process Not Found');
                }
            },
            async handleCreateDraft(versionId) {
                try {
                    await this.createDraft({versionId, processId: this.process.id});
                    await this.initialize();
                    this.success('Successfully created draft!', 'Create Draft');
                } catch {
                    this.error('Failed to create draft!', 'Create Draft');
                }
            },
            async handlePublish({version, date}) {
                try {
                    let updatedVersion = await this.publishVersion({
                            versionId: version.id,
                            processId: this.process.id,
                            date,
                        }),
                        action = this.isPublished(updatedVersion) ? 'published' : 'scheduled';

                    await this.initialize();
                    this.success(`Successfully ${action} version!`, 'Publish Version');
                } catch {
                    this.error('Failed to publish version!', 'Publish Version');
                }
            },
            async handleUnschedulePublish({version}) {
                try {
                    await this.unschedulePublish({versionId: version.id, processId: this.process.id});
                    await this.initialize();
                    this.success('Successfully unscheduled version!', 'Publish Version');
                } catch {
                    this.error('Failed to unschedule version!', 'Publish Version');
                }
            },
            async handleRevert({version, date}) {
                try {
                    let updatedVersion = await this.revertVersion({
                            versionId: version.id,
                            processId: this.process.id,
                            date,
                        }),
                        action = this.isReverted(updatedVersion) ? 'reverted version' : 'scheduled version to be reverted';

                    await this.initialize();
                    this.success(`Successfully ${action}!`, 'Revert Version');
                } catch {
                    this.error('Failed to revert version!', 'Revert Version');
                }
            },
            async handleUnscheduleRevert({version}) {
                try {
                    await this.revertVersion({versionId: version.id, processId: this.process.id});
                    await this.initialize();
                    this.success('Successfully unscheduled revert!', 'Revert Version');
                } catch {
                    this.error('Failed to unschedule revert!', 'Revert Version');
                }
            },
            async handleObsolete(date) {
                try {
                    let updatedProcess = await this.obsoleteProcess({processId: this.process.id, date: date.date}),
                        action = this.isScheduledForObsolete(updatedProcess) ? 'scheduled process to be obsoleted' : 'obsoleted process';

                    await this.initialize();
                    this.success(`Successfully ${action}!`, 'Obsolete Process');
                } catch {
                    this.error('Failed to obsolete process!', 'Obsolete Process');
                }
            },
            async handleUnscheduleObsolete() {
                try {
                    await this.obsoleteProcess({processId: this.process.id});
                    await this.initialize();
                    this.success('Successfully unscheduled obsolete!', 'Obsolete Process');
                } catch {
                    this.error('Failed to unschedule obsolete!', 'Obsolete Process');
                }
            },
            async handleDeleteVersion({version}) {
                try {
                    await this.deleteVersion({versionId: version.id, processId: this.process.id});
                    await this.initialize();
                    this.success('Successfully deleted version!', 'Delete Version');
                } catch {
                    this.error('Failed to delete version!', 'Delete Version');
                }
            },
            async handleDeleteProcess() {
                try {
                    await this.deleteProcess({processId: this.process.id});
                    this.success('Successfully deleted process!', 'Delete Process');
                    this.$bvModal.hide('processOptions');
                } catch {
                    this.error('Failed to delete process!', 'Delete Process');
                }
            },
            async handleHidden() {
                await this.$router.push({name: 'admin.processes.list'});
            },
            ...mapActions([
                'createDraft',
                'deleteProcess',
                'deleteVersion',
                'fetchProcess',
                'obsoleteProcess',
                'publishVersion',
                'revertVersion',
                'unschedulePublish',
            ]),
            ...mapMutations('builder', [
                'SET_DATA',
            ]),
        },
    };
</script>

<style lang="sass">
    .options-modal
        .modal-xl
            @media (min-width: 576px)
                max-width: 95%

            @media (min-width: 1200px)
                max-width: 1140px
</style>
